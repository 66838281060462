import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// import uuid from "react-uuid"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const DeclineAnalysisChartFour = React.memo(({ data }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));

  const [rows, setRows] = useState([])
  
  useEffect(()=>{
    if(data?.time_series?.length > 0) {
      let newSeries = []
      data.time_series.forEach(item=>{
        const temp = { 
          pagos_code_short: item.pagos_code_short,
          count: item.count,
          payment_processor_name: item.payment_processor_name
        }
        newSeries.push(temp)
      })
      setRows(newSeries)
    }
  },[])
  return (
    <Item>
      <>
        <Typography gutterBottom variant="h5" component="div">
          Decline Code - Top 10
        </Typography>
        {rows.length > 0 ? <TableContainer component={Paper}  style={{height:'340px'}}>
          <Table sx={{ minWidth: 320 }} aria-label="a dense table" >
            <TableHead>
              <TableRow>
                <TableCell>Gateway Name</TableCell>
                <TableCell align="right">Error Code</TableCell>
                <TableCell align="right">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={`${row.payment_processor_name}-${Math.random()}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.payment_processor_name}
                  </TableCell>
                  <TableCell align="right">{row.pagos_code_short}</TableCell>
                  <TableCell align="right">{row.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>: 'No Data'}
        
      </>
    </Item>
  );
})

export default DeclineAnalysisChartFour;
