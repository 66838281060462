import React, { useEffect, useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ReactECharts from 'echarts-for-react';
import { dayMonthFormattor } from '../../../utils';
import { Typography } from '@mui/material';

function splitArrayIntoCommonKeys(data){
  return data.time_series.reduce(function(newArr, obj) {  
        var key = obj.pm_card_type;                               
        var newObj = {
          approval_rate: obj.approval_rate,
          date: obj.date,
          date_iso: obj.date_iso,
          day: obj.day,
          end_date_iso: obj.end_date_iso,
          month: obj.month,
          pm_card_type: obj.pm_card_type,
          start_date_iso: obj.start_date_iso,
          year: obj.year
        };   
        if(newArr[key])                                               
          newArr[key].push(newObj);                                   
        else                                                         
          newArr[key] = [ newObj ];                                    
        return newArr
      }, {});
}
const CardTypeChartThree = React.memo(({ data }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));

  const [chartOptions, setChartOptions] = useState( {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: []
    },
    grid: { top: 40, right: 8, bottom: 24, left: 36 },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      data: []
    },
    yAxis: {
      type: 'value',
    },
    series: [
    ]
  })

  useEffect(() => {
    if(data?.time_series?.length > 0) {
      const splitObj = splitArrayIntoCommonKeys(data)
      const legends = Object.keys(splitObj)
      let xValues = []
      splitObj[legends[0]].forEach(item => {
        xValues.push(dayMonthFormattor(item.date))
      })
      let seriesArr = []
      legends.forEach(legend=>{
        let singleSeries = {
          name: legend,
          type: 'line',
          data: []
        }
        splitObj[legend].forEach(item => {
          singleSeries.data.push(Math.floor(item.approval_rate))
        })
        seriesArr.push(singleSeries)
      })
      setChartOptions({
        ...chartOptions,
        legend: {
          data: legends
        },
        xAxis: {
          type: 'category',
          data: xValues,
        },
        series: seriesArr
      })
    }
  }, [data])
  return (
    <Item>
      <Typography gutterBottom variant="h5" component="div">
      Card Type Approval Rate
    </Typography>
    {chartOptions && data?.time_series?.length > 0 ? <>
    <ReactECharts option={chartOptions} />
    </> : 'No Data'}</Item>
  );
})

export default CardTypeChartThree;
