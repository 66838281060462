export const LineChartColor = ["#1890FF"]

export const CompareColors = ["#1890FF", "#94DAFB"]

export const StatusColors = {
	approved: "#28C76F",
	declined: "#EB5757",
	unknown: "#44B8FF"
}

export const PaymentProvidersColors = {
	stripe: "#5851DF",
	braintree: "#002D8B",
	adyen: "#28C76F",
	unknown: "#44B8FF"
}

export const NetworkColors = {
	visa: "#002D8B",
	discover: "#242424",
	jcb: "#28C76F",
	mastercard: "#F5821F",
	"american express": "#1890FF",
	unknown: "#44B8FF"
}

export const CardTypesColors = {
	credit: "#1890FF",
	debit: "#002D8B",
	prepaid: "#28C76F",
	unknown: "#44B8FF"
}

export const HeatMapColors = [
	"#5FC5FF", "#50BAFF", "#41AEFF", "#32A3FF", "#2298FF", "#1789F7",
	"#137AE5", "#0F6AD2", "#0C5BC1", "#084CAF", "#043D9D", "#002D8B"
]

export const ChargebackVolumeColor = "#002B95"
export const ChargebackRateColor = "#1890FF"

export const DefaultColors = [
	"#0B016B", "#0249D4", "#3BA0FD", "#17A2F0", "#045E9E", "#025A6E", "#1191B9", "#00CBD8", "#08D0BD", "#0F8385",
	"#085E49", "#0C9C7B", "#08D088", "#002B95", "#0073F4", "#5FC5FF", "#0476CA", "#064470", "#067B95", "#18B0D1",
	"#0073F4", "#0AAEA7", "#0C4E54", "#0A7E63", "#12B175", "#0B016B", "#0249D4", "#3BA0FD", "#17A2F0", "#045E9E",
	"#025A6E", "#1191B9", "#00CBD8", "#08D0BD", "#0F8385", "#085E49", "#0C9C7B", "#08D088", "#002B95", "#0073F4",
	"#5FC5FF", "#0476CA", "#064470", "#067B95", "#18B0D1", "#0073F4", "#0AAEA7", "#0C4E54", "#0A7E63", "#12B175"
]

export const MapMorphChartColors = ["#038F6E", "#1EBC6C", "#D4D828", "#F8DB4E", "#FFC246", "#F9962B", "#E66131", "#E9553A", "#F24646"]