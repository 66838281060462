import { Button, Grid, TextField } from "@mui/material";
import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function DateRangeFilter({ startDate,  endDate, setStartDate, setEndDate, setParams}) {
    const StartDateInput = forwardRef(({ value, onClick }, ref) => (
        <TextField size="small" label="Start Date" onClick={onClick} ref={ref} value={value} variant="outlined" />
    ));
    const EndDateInput = forwardRef(({ value, onClick }, ref) => (
        <TextField size="small" label="End Date" onClick={onClick} ref={ref} value={value} variant="outlined" />
    ));
    return (
        <>
            <Grid container spacing={1} marginBottom={2} >
                <Grid item xs={2}>
                    <div>
                        <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        customInput={<StartDateInput />}/>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div>
                        <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        customInput={<EndDateInput />}/>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div>
                        <Button onClick={() => {
                            setParams({
                                frequency: 'daily',
                                conversion_currency_code: 'usd',
                                start_date: startDate.toISOString(),
                                end_date: endDate.toISOString(),
                            })
                        }}>Apply Filter</Button>
                    </div>
                </Grid>
            </Grid>


        </>
    );
}