import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// import uuid from "react-uuid"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const FlagsChartOne = React.memo(({ data }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));

  const [rows, setRows] = useState([])
  
  useEffect(()=>{
    if(data?.time_series?.length > 0) {
      let newSeries = []
      data.time_series.forEach(item=>{
        const temp = { 
          pagos_code_short: item.pagos_code_short,
          count: item.count,
          date: item.date,
          currency: item.currency,
          checks_cvc_check: item.checks_cvc_check,
          checks_address_postal_code_check: item.checks_address_postal_code_check,
          total_amount: item.total_amount,
          share_percentage: item.share_percentage,
        }
        newSeries.push(temp)
      })
      setRows(newSeries)
    }
  },[])
  return (
    <Item>
      <>
        <Typography gutterBottom variant="h5" component="div">
        Transaction Flag Details
        </Typography>
        <TableContainer component={Paper}  style={{height:'340px'}}>
          <Table sx={{ minWidth: 320 }} aria-label="a dense table" >
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">CVV Check</TableCell>
                <TableCell align="right">Postal Code Check</TableCell>
                <TableCell align="right">Currency</TableCell>
                <TableCell align="right">Transaction Count</TableCell>
                <TableCell align="right">Volume</TableCell>
                <TableCell align="right">Share</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={`${row.date}-${Math.random()}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{row.date}</TableCell>
                  <TableCell align="right">{row.pagos_code_short}</TableCell>
                  <TableCell align="right">{row.checks_cvc_check}</TableCell>
                  <TableCell align="right">{row.checks_address_postal_code_check}</TableCell>
                  <TableCell align="right">{row.currency}</TableCell>
                  <TableCell align="right">{row.count}</TableCell>
                  <TableCell align="right">{row.total_amount}</TableCell>
                  <TableCell align="right">{row.share_percentage}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </Item>
  );
})

export default FlagsChartOne;
