import React, { useEffect, useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ReactECharts from 'echarts-for-react';
import { dayMonthFormattor } from '../../utils';
import { Typography } from '@mui/material';

const LineChartFive = React.memo(({data }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));

  const [chartOptions, setChartOptions] = useState({
    grid: { top: 40, right: 8, bottom: 24, left: 36 },
    axisPointer: {
      lineStyle: {
        color: "rgba(24, 144, 255, 0.4)"
      }
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      data: []
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [],
        type: 'bar'
      }
    ],
    tooltip: {
      trigger: 'axis',
    },
  })

  useEffect(() => {
    if (data.time_series.length) {
      let xValues = []
      data.time_series.forEach(item => {
        xValues.push(dayMonthFormattor(item.date))
      })
      let yValues = []
      data.time_series.forEach(item => {
        yValues.push(item.count)
        setChartOptions({
          ...chartOptions,
          xAxis: {
            type: 'category',
            data: xValues,
          },
          series: [
            {
              data: yValues,
              type: 'bar',
            }
          ]
        })
      })
    }
  }, [data])
  return (
    <Item>
      <Typography gutterBottom variant="h5" component="div">
      Chargebacks
    </Typography>
    {chartOptions && data?.time_series?.length > 0 ? <>
    <ReactECharts option={chartOptions} />
    </> : 'No Data'}</Item>
  );
})

export default LineChartFive;
