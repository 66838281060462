import { createContext, useEffect, useReducer } from "react";
import AuthReducer from "./AuthReducer";

const INITIAL_STATE = {
  token: localStorage.getItem("token"),
  env: localStorage.getItem("env"),
  isFetching: false,
  error: false,
};


export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
  
  useEffect(()=>{
    if(state.token) {
      localStorage.setItem("token",state.token)
    }
  },[state.token])

  useEffect(()=>{
    if(state.env) {
      localStorage.setItem("env",state.env)
    }
  },[state.env])
  
  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        env: state.env,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
