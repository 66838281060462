import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { LineChartColor } from "../../../shared/charts-color-palette/color-palette"
import WorldMap from 'react-svg-worldmap';
import React from 'react';

const IssuerChartOne = React.memo(({ requestData }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));


  const { time_series: data } = requestData

  const mapData = data.map((x) => ({
    country: x.pm_card_issuer_country,
    value: x.percentage_succeeded
  }))
  return (
    <Item>
      <>
        <Typography gutterBottom variant="h5" component="div">
          Issuer Country Approval Rate
        </Typography>
          {data && (
            <WorldMap
              tooltipTextFunction={(context) => `${context.countryName} - ${(context.countryValue)}%`}
              title=""
              value-suffix="people"
              size={365}
              color={LineChartColor[0]}
              data={mapData}
              styleFunction={
                (context) => {
                  const opacityLevel = (context.countryValue || 0) / 100

                  if (opacityLevel === 0) {
                    return {
                      fill: "transparent",
                      stroke: "#000"
                    }
                  }

                  return {
                    fill: LineChartColor[0],
                    fillOpacity: opacityLevel
                  }
                }
              }
            />
          )}
      </>
    </Item>
  );
})

export default IssuerChartOne;
