import React, { useEffect, useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ReactECharts from 'echarts-for-react';
import { dayMonthFormattor } from '../../../utils';
import { Typography } from '@mui/material';


function splitArrayIntoCommonKeys(data){
    return data.time_series.reduce(function(newArr, obj) {  
          var key = "Refund success score";
          var key1 = "Refund volume";                                
          var newObj = {
            count: obj.count,
            date: obj.date,
            date_iso: obj.date_iso,
            day: obj.day,
            end_date_iso: obj.end_date_iso,
            month: obj.month,
            success_rate: obj.success_rate,
            start_date_iso: obj.start_date_iso,
            year: obj.year
          };   
          if(newArr[key])                                               
            newArr[key].push(newObj);                                   
          else                                                         
            newArr[key] = [ newObj ];
          if(newArr[key1])                                               
            newArr[key1].push(newObj);                                   
          else                                                         
            newArr[key1] = [ newObj ];                                     
          return newArr
        }, {});
}
const RefundsChartTwo = React.memo(({ data }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));

  const [chartOptions, setChartOptions] = useState( {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: []
    },
    grid: { top: 40, right: 100, bottom: 24, left: 50 },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      data: []
    },
    yAxis: [
      {
        type: 'value',
        name: 'Refund success score',
        position: 'right',
        alignTicks: true,
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: '{value} %'
        }
      },
      {
        type: 'value',
        name: 'Refund volume',
        position: 'left',
        alignTicks: true,
        axisLine: {
          show: true,
        }
      }
    ],
    series: [
    ]
  })

  useEffect(() => {
    if(data?.time_series?.length > 0) {
      const splitObj = splitArrayIntoCommonKeys(data)
      const legends = Object.keys(splitObj)
      let xValues = []
      splitObj[legends[0]].forEach(item => {
        xValues.push(dayMonthFormattor(item.date))
      })
      let seriesArr = []
      let singleSeries1 = {
        name: "Refund success score",
        type: 'line',
        data: []
      }
      let singleSeries2 = {
        name: "Refund volume",
        type: 'bar',
        yAxisIndex: 1,
        data: []
      }
      legends.forEach(legend=>{
        splitObj[legend].forEach(item => {
          if(legend === "Refund success score") {
            singleSeries1.data.push(item.success_rate)
          } else {
            singleSeries2.data.push(item.count)
          }
        })
      })
      seriesArr.push(singleSeries1)
      seriesArr.push(singleSeries2)
      setChartOptions({
        ...chartOptions,
        legend: {
          data: legends
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: xValues,
        },
        series: seriesArr
      })
    }
  }, [data])
  return (
    <Item>
      <Typography gutterBottom variant="h5" component="div">
      Refund Volume and Success Rate
    </Typography>
      {chartOptions && data?.time_series?.length > 0 ? <>
    <ReactECharts option={chartOptions} />
    </> : 'No Data'}</Item>
  );
})

export default RefundsChartTwo;
