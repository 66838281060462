import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Dashboard from './components/dashboard/dashboard';
import Reports from './components/reports/reports';
import Login from './components/login/login';
import { useContext } from 'react';
import { AuthContext } from './context/AuthContext';
import Network from './components/reports/network/network';
import Processor from './components/reports/processor/processor';
import CardType from './components/reports/card-type/cardtype';
import Currency from './components/reports/currency/currency';
import Issuer from './components/reports/issuer/issuer';
import StoredCredentails from './components/reports/stored-credentials/stored-credentials';
import Flags from './components/reports/flags/flags';
import DeclineAnalysis from './components/reports/decline-analysis/decline-analysis';
import Settlements from './components/reports/settlements/settlements';
import Refunds from './components/reports/refunds/refunds';
import Chargebacks from './components/reports/chargebacks/chargebacks';

function App() {
  localStorage.removeItem("token")
  const { token } = useContext(AuthContext);
  return (
    <Router>
      <div className="App">
        

        {token ? (
          <>
            <Routes>
              <Route index element={<Dashboard/>}/>
              <Route path="/dashboard" element={<Dashboard/>}/>
              <Route path="reports" element={<Reports/>}>
                  <Route index element={<Processor />} />
                  <Route path="processor" element={<Processor />} />
                  <Route path="network" element={<Network />} />
                  <Route path="card-type" element={<CardType />} />
                  <Route path="currency" element={<Currency />} />
                  <Route path="issuer" element={<Issuer />} />
                  <Route path="stored-credentails" element={<StoredCredentails />} />
                  <Route path="flags" element={<Flags />} />
                  <Route path="decline-analysis" element={<DeclineAnalysis />} />
                  <Route path="settlements" element={<Settlements />} />
                  <Route path="refunds" element={<Refunds />} />
                  <Route path="chargebacks" element={<Chargebacks />} />
                  
              </Route>
            </Routes>
          </>
          ):
          <Routes>
            <Route path="/" element={<Login/>}/>
          </Routes>}
      </div>
    </Router>

  );
}

export default App;
