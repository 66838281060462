import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Chart } from '../../shared/chart/chart'
import { Typography } from '@mui/material';
import { DefaultColors } from "../../../shared/charts-color-palette/color-palette"
import React from 'react';

const getChartConfig = (series = [], xField = "status", yField = "count") => ({
	color: DefaultColors,
	tooltip: {
		trigger: "item",
		appendToBody: true,
		transitionDuration: 0,
		borderColor: "#FFFFFF"
	},
	series: {
		type: "pie",
		radius: ["35%", "65%"],
		center: ["50%", "50%"],
		data: series.map((item) => ({
			name: item[xField],
			value: item[yField]
		})),
		label: {
			position: "outer",
			alignTo: "none",
			bleedMargin: 5,
			overflow: "truncate"
		},
		width: "100%"
	},
	grid: {
		top: 30, left: 65, right: 65, bottom: 30
	}
})
const DeclineAnalysisChartOne = React.memo(({
	requestData = {},
	keyField = "intent",
	xField = "status",
	yField = "count"
}) => {
	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(2),
		textAlign: 'center',
		height: '370px',
		color: theme.palette.text.secondary,
	}));

	const { time_series: data } = requestData

	// parse incoming data by metric
	const parseData = (result, item) => {
		const metric = item[keyField]

		if (!result[metric]) {
			result[metric] = [item]
			return result
		}

		result[metric].push(item)
		return result
	}

	const series = data.reduce(parseData, {})

	const seriesKeys = Object.keys(series)

	const charts = seriesKeys.map((key) => {
		const keySeries = series[key] || []

		return {
			key,
			chartUiKey: Math.random(),
			config: getChartConfig(keySeries, xField, yField),
			title: `${key} Stored Credential`
		}
	})

	return (
		<Item>
			<Typography gutterBottom variant="h5" component="div">
				Decline Code Distribution
			</Typography>

			{charts.length > 0 ? <>
				{
					charts.map((chart) => (
						<div key={chart.chartUiKey}>
							<h4>{chart.title}</h4>
							<Chart options={chart.config} />
						</div>
					))
				}
			</> : 'No Data'}

		</Item>
	);
})

export default DeclineAnalysisChartOne;
