import { useContext, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ChargebacksChartOne from './chargebacks-chart-one';
import ChargebacksChartTwo from './chargebacks-chart-two';
import { AuthContext } from '../../../context/AuthContext';
import DateRangeFilter from '../../date-range-filter/date-range-filter';
import ChargebacksChartThree from './chargebacks-chart-three';
import { MapMorphChart } from './map-morph-chart';
import { getCountryWithCodeByCode } from "./country-list/country-list"
import { ChargebackRateColor, ChargebackVolumeColor, MapMorphChartColors } from "../../../shared/charts-color-palette/color-palette"
import { kFormatter, percentageNumberFormatting } from '../../../utils';
import ChargebacksChartFive from './chargebacks-chart-five';
import ChargebacksChartSix from './chargebacks-chart-six';

const pChartsParser = require('peacock-charts-sdk')
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  height: '370px',
  color: theme.palette.text.secondary,
}));
function removeTokenAndReload() {
  localStorage.removeItem("token")
  window.location.reload()
}
const dateToday = new Date()
function Chargebacks() {

  const { token,env } = useContext(AuthContext);
  const [startDate, setStartDate] = useState(new Date(dateToday.getTime() - (6 * 24 * 60 * 60 * 1000)));
  const [endDate, setEndDate] = useState(dateToday);
  const [params, setParams] = useState({
    frequency: 'daily',
    conversion_currency_code: 'usd',
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
  })
  const [chart1Data, setChart1Data] = useState(null)
  const [chart1Loading, setChart1Loading] = useState(true)
  const [chart2Data, setChart2Data] = useState(null)
  const [chart2Loading, setChart2Loading] = useState(true)
  const [chart3Data, setChart3Data] = useState(null)
  const [chart3Loading, setChart3Loading] = useState(true)
  const [chart4Data, setChart4Data] = useState(null)
  const [chart4Loading, setChart4Loading] = useState(true)
  const [chart5Data, setChart5Data] = useState(null)
  const [chart5Loading, setChart5Loading] = useState(true)
  const [chart6Data, setChart6Data] = useState(null)
  const [chart6Loading, setChart6Loading] = useState(true)
  useEffect(() => {
    setChart1Loading(true)
    setChart2Loading(true)
    pChartsParser.pChart60ChargebacksVolumeAndRate(env, token, params).then(res => {
      if (res !== null) {
        setChart1Data(res.data_bag)
        setChart1Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })
    pChartsParser.pChart61ChargebacksVolumeByStatus(env, token, params).then(res => {
      if (res !== null) {
        setChart2Data(res.data_bag)
        setChart2Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })
    pChartsParser.pChart62ChargebacksVolumeAndRateByProcessor(env, token, params).then(res => {
      if (res !== null) {
        setChart3Data(res.data_bag)
        setChart3Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })
    pChartsParser.pChart63ChargebacksVolumeByIssuerCountry(env, token, params).then(res => {
      if (res !== null) {
        setChart4Data(res.data_bag)
        setChart4Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })
    pChartsParser.pChart64ChargebacksVolumeAndRateByIssuerCountry(env, token, params).then(res => {
      if (res !== null) {
        setChart5Data(res.data_bag)
        setChart5Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })
    pChartsParser.pChart66ChargebacksReasonCodeDistribution(env, token, params).then(res => {
      if (res !== null) {
        setChart6Data(res.data_bag)
        setChart6Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })

  }, [params])
  return (
    <Container maxWidth="xl">
      <Card sx={{ marginTop: '16px', padding: '16px 8px' }}>
        <Typography gutterBottom variant="h5" component="div">
          Chargebacks
        </Typography>
        <DateRangeFilter startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} setParams={setParams} />
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} md={6} lg={4}>
            {!chart1Loading ? <ChargebacksChartOne data={chart1Data} /> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart2Loading ? <ChargebacksChartTwo data={chart2Data} /> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart3Loading ? <ChargebacksChartThree data={chart3Data} /> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart4Loading ? <MapMorphChart
              requestData={chart4Data}
              nameField={"pm_card_issuer_country"}
              fullNameField={"pm_card_issuer_country_full"}
              nameFormatter={getCountryWithCodeByCode}
              valueField={"count"}
              colorField={"rate"}
              colorFieldFormatter={(value) => `${value}%`}
              colors={MapMorphChartColors}
            /> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart5Loading ? <ChargebacksChartFive
            requestData={chart5Data}
            params={params}
              xField={"pm_card_issuer_country"}
              yBarField={"count"}
              yLineField={"rate"}
              chartsNames={{
                lineChartName: "Chargeback rate",
                barChartName: "Chargeback count"
              }}
              chartOptions={{
                yAxisBarLabelFormatter: value => kFormatter(value),
                yAxisLineLabelFormatter: value => `${percentageNumberFormatting(value)}%`,
                lineValueFormatter: percentageNumberFormatting
              }}
              colors={{
                barChartColor: ChargebackVolumeColor,
                lineChartColor: ChargebackRateColor
              }}
            /> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart6Loading ? <ChargebacksChartSix data={chart6Data} /> : <Item>Loading</Item>}
          </Grid>




        </Grid>
      </Card>
    </Container>
  );
}

export default Chargebacks;
