import { useContext, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import SelltlementsChartOne from './settlements-chart-one';
import SelltlementsChartTwo from './settlements-chart-two';
import SelltlementsChartThree from './settlements-chart-three';
import { AuthContext } from '../../../context/AuthContext';
import DateRangeFilter from '../../date-range-filter/date-range-filter';

const pChartsParser  =  require('peacock-charts-sdk')
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  height: '370px',
  color: theme.palette.text.secondary,
}));
function removeTokenAndReload() {
  localStorage.removeItem("token")
  // window.location.reload()
}
const dateToday = new Date()
function Settlements() {

  const { token,env } = useContext(AuthContext);
  const [startDate, setStartDate] = useState(new Date(dateToday.getTime() - (6 * 24 * 60 * 60 * 1000)));
  const [endDate, setEndDate] = useState(dateToday);
  const [params, setParams] = useState({
    frequency: 'daily',
    conversion_currency_code: 'usd',
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
  })

  const [chart1Data, setChart1Data] = useState(null)
  const [chart1Loading, setChart1Loading] = useState(true)

  const [chart2Data, setChart2Data] = useState(null)
  const [chart2Loading, setChart2Loading] = useState(true)

  const [chart3Data, setChart3Data] = useState(null)
  const [chart3Loading, setChart3Loading] = useState(true)
  

  useEffect(() => {
    setChart1Loading(true)
    setChart2Loading(true)
    setChart3Loading(true)
    pChartsParser.pChart70SettlementsByCurrency(env,token,params).then(res => {
      if (res?.data_bag?.time_series?.length) {
        setChart1Data(res.data_bag)
      }
      setChart1Loading(false)
    }).catch(err=> {
      removeTokenAndReload()
    })
    pChartsParser.pChart71Settlements(env,token,params).then(res => {
      if (res?.data_bag?.time_series?.length) {
        setChart2Data(res.data_bag)
      }
      setChart2Loading(false)
    }).catch(err=> {
      removeTokenAndReload()
    })
    pChartsParser.pChart72SettlementsByProcessorAndCurrency(env,token,params).then(res => {
      if (res?.data_bag?.time_series?.length) {
        setChart3Data(res.data_bag)
      }
      setChart3Loading(false)
    }).catch(err=> {
      removeTokenAndReload()
    })
  }, [params])
  return (
    <Container maxWidth="xl">
      <Card sx={{ marginTop: '16px', padding: '16px 8px' }}>
        <Typography gutterBottom variant="h5" component="div">
            Settlements
        </Typography>
        <DateRangeFilter startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} setParams={setParams} />
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} md={6} lg={4}>
            {!chart1Loading? <SelltlementsChartOne data={chart1Data}/> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart2Loading? <SelltlementsChartTwo data={chart2Data}/> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart3Loading? <SelltlementsChartThree data={chart3Data}/> : <Item>Loading</Item>}
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}

export default Settlements;
