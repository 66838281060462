import { React, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';

import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Collapse, ListItemButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';

const drawerWidth = 180;

const reportsNav = [
  {
    text: "Processor",
    link: ""
  },
  {
    text: "Network",
    link: "/network"
  },
  {
    text: "Card Type",
    link: "/card-type"
  },
  {
    text: "Currency",
    link: "/currency"
  },
  {
    text: "Issuer",
    link: "/issuer"
  },
  {
    text: "Stored Credentials",
    link: "/stored-credentails"
  },
  {
    text: "Flags",
    link: "/flags"
  },
  {
    text: "Decline Analysis",
    link: "/decline-analysis"
  },
  {
    text: "Custom",
    link: "/custom"
  },
]

export default function PermanentDrawerLeft() {
  const location = useLocation()
  const navigate = useNavigate()
  const [openReports, setOpenReports] = useState(true)
  const handleReportsClick = () => {
    setOpenReports(!openReports);
  };
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />
      <List dense={true}>
        <ListItemButton onClick={handleReportsClick}>
          <ListItemText primary="Reports" />
          {openReports ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openReports} timeout="auto" unmountOnExit>
          <List dense={true} >

            {reportsNav.map((item, index) => (
              <ListItem button key={item.text}  onClick={() =>  navigate(`/reports${item.link}`)}
              selected={`/reports${item.link}` === location.pathname}>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>


      <Divider />
      <List dense={true}>
        <ListItemButton  onClick={() =>  navigate('/reports/settlements')}  selected={'/reports/settlements' === location.pathname} >
          <ListItemText primary="Settlements" />
        </ListItemButton>
        <ListItemButton >
          <ListItemText primary="Refunds"  onClick={() =>  navigate('/reports/refunds')}  selected={'/reports/refunds' === location.pathname} />
        </ListItemButton>
        <ListItemButton >
          <ListItemText primary="Chargebacks" onClick={() =>  navigate('/reports/chargebacks')}  selected={'/reports/chargebacks' === location.pathname}/>
        </ListItemButton>
        <ListItemButton >
          <ListItemText primary="Custom" />
        </ListItemButton>
      </List>
      {/* <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </Drawer>
  );
}
