import React, { useEffect, useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ReactECharts from 'echarts-for-react';
import { dayMonthFormattor, kFormatter } from '../../../utils';
import { Typography } from '@mui/material';


function splitArrayIntoCommonKeys(data) {
  return data.time_series.reduce(function (newArr, obj) {
    var key = `${obj.payment_processor_name} rate`;
    var key2 = `${obj.payment_processor_name} volume`;
    var newObj = {
      payment_processor_name: obj.payment_processor_name,
      count: obj.count,
      rate: obj.rate,
      pagos_reason_category: obj.pagos_reason_category,
      date: obj.date,
      date_iso: obj.date_iso,
      day: obj.day,
      end_date_iso: obj.end_date_iso,
      month: obj.month,

      start_date_iso: obj.start_date_iso,
      year: obj.year
    };
    if (newArr[key])
      newArr[key].push(newObj);
    else
      newArr[key] = [newObj];
    if (newArr[key2])
      newArr[key].push(newObj);
    else
      newArr[key2] = [newObj];
    return newArr
  }, {});
}
const ChargebacksChartThree = React.memo(({ data }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));

  const [chartOptions, setChartOptions] = useState({
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: []
    },
    grid: { top: 80, right: 70, bottom: 24, left: 80 },
    xAxis: {
      type: 'category',
      data: []
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        margin: 25,
        formatter: function (value, index) {
          return kFormatter(value);
        }
      }
    },
    series: [
    ]
  })

  useEffect(() => {
    if (data?.time_series?.length > 0) {
      const splitObj = splitArrayIntoCommonKeys(data)
      const legends = Object.keys(splitObj)
      let xValues = []
      splitObj[legends[0]].forEach(item => {
        xValues.push(dayMonthFormattor(item.date))
      })
      let seriesArr = []
      legends.forEach(legend=>{
        let singleSeries = {
          name: legend,
          type: 'bar',
          stack: 'total',
          emphasis: {
            focus: 'series'
          },
          data: []
        }
        splitObj[legend].forEach(item => {
          singleSeries.data.push(item.count)
        })
        seriesArr.push(singleSeries)
      })
      let seriesArr2 = []
      legends.forEach(legend=>{
        let singleSeries = {
          name: legend,
          type: 'bar',
          stack: 'total',
          emphasis: {
            focus: 'series'
          },
          data: []
        }
        splitObj[legend].forEach(item => {
          singleSeries.data.push(item.rate)
        })
        seriesArr2.push(singleSeries)
      })
      setChartOptions({
        ...chartOptions,
        legend: {
          data: legends,
          type: 'scroll'
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: xValues,
        },
        series: seriesArr
      })
    }
  }, [data])
  return (
    <Item>
      <Typography gutterBottom variant="h5" component="div">
        Chargeback Volume by Processor
      </Typography>
      {chartOptions && data?.time_series?.length > 0 ?<>
      <ReactECharts option={chartOptions} />
    </> : 'No Data'}</Item>
  );
})

export default ChargebacksChartThree;
