import { Box } from "@mui/system";
import React from "react"
import { Outlet } from "react-router-dom";
import PermanentDrawerLeft from "../drawer/drawer";

import Header from '../header/header';
function Reports() {
  return (
    <>
      <Header />
      <Box sx={{ display: 'flex' }}>
        <PermanentDrawerLeft />

        <Box style={{width: "100%"}}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default Reports;
