import countryList from "react-select-country-list"

countryList().setLabel("CG", "Republic of Congo").getLabel("CG")
countryList().setLabel("CD", "Democratic Republic of the Congo").getLabel("CD")
countryList().setLabel("BO", "Plurinational State of Bolivia").getLabel("BO")
countryList().setLabel("IR", "Islamic Republic of Iran").getLabel("IR")
countryList().setLabel("KP", "Democratic People’s Republic of Korea").getLabel("KP")
countryList().setLabel("KR", "Republic of Korea").getLabel("KR")
countryList().setLabel("FM", "Federated States of Micronesia").getLabel("FM")
countryList().setLabel("MD", "Republic of Moldova").getLabel("MD")
countryList().setLabel("PS", "State of Palestine").getLabel("PS")
countryList().setLabel("TW", "Republic of China (Taiwan)").getLabel("TW")
countryList().setLabel("US", "United States of America").getLabel("US")
countryList().setLabel("VE", "Bolivarian Republic of Venezuela").getLabel("VE")
countryList().setLabel("CZ", "Czech Republic").getLabel("CZ")
countryList().setLabel("TZ", "United Republic of Tanzania").getLabel("TZ")

const CountryList = () => {
	return countryList().getData()
}

export const getCountryByCode = (code) => {
	if (!code) {
		return code
	}
	// TO DO : update translation dictionaries
	return countryList().getLabel(code) || code
}

export const getCountryWithCodeByCode = (code) => {
	const countryName = getCountryByCode(code)
	if (countryName === code) {
		return code
	}
	return `${countryName} (${code})`
}

export const getCodeByCountry = (country) => {
	return countryList().getValue(country) || null
}

export default CountryList