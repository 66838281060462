import React, { useEffect, useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ReactECharts from 'echarts-for-react';
import { dayMonthFormattor, kFormatter } from '../../utils';
import { Typography } from '@mui/material';

function splitArrayIntoCommonKeys(data){
  return data.time_series.reduce(function(newArr, obj) {  
        var key = obj.payment_processor_name;                               
        var newObj = {
          total_amount: obj.total_amount,
          date: obj.date,
          date_iso: obj.date_iso,
          day: obj.day,
          end_date_iso: obj.end_date_iso,
          month: obj.month,
          payment_processor_name: obj.payment_processor_name,
          start_date_iso: obj.start_date_iso,
          year: obj.year
        };   
        if(newArr[key])                                               
          newArr[key].push(newObj);                                   
        else                                                         
          newArr[key] = [ newObj ];                                    
        return newArr
      }, {});
}
const LineChartSix = React.memo(({data }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));

  const [chartOptions, setChartOptions] = useState({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {},
    grid: {
      left: '5%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: []
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        margin: 25,
        formatter: function (value, index) {
          return kFormatter(value);
        }
      }
    },
    series: []
  })

  useEffect(() => {
    if (data.time_series.length) {
      const splitObj = splitArrayIntoCommonKeys(data)
      const legends = Object.keys(splitObj)
      if(legends.length !== 0) {
        let xValues = []
        splitObj[legends[0]].forEach(item => {
          xValues.push(dayMonthFormattor(item.date))
        })
        let seriesArr = []
        legends.forEach(legend=>{
          let singleSeries = {
            name: legend,
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: []
          }
          splitObj[legend].forEach(item => {
            singleSeries.data.push(item.total_amount)
          })
          seriesArr.push(singleSeries)
        })
        setChartOptions({
          ...chartOptions,
          legend: {
            data: legends
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xValues,
          },
          series: seriesArr
        })
      }
      
    }
  }, [data])
  return (
    <Item>
      <Typography gutterBottom variant="h5" component="div">
      Settled Volume by Processor
    </Typography>
    {chartOptions && data?.time_series?.length > 0 ? <>
    <ReactECharts option={chartOptions} />
    </> : 'No Data'}</Item>
  );
})

export default LineChartSix;
