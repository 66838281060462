import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Chart } from '../../shared/chart/chart'
import { Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
function getAggregatedData(data, valueProperty, firstProperty, secondProperty) {
  const result = {}
  data.forEach((item) => {
    const firstPropertyValue = item[firstProperty]
    const secondPropertyValue = item[secondProperty]
    const value = item[valueProperty]
    if (!result[firstPropertyValue]) {
      result[firstPropertyValue] = !secondProperty ? value : {}
    } else if (!secondProperty) {
      result[firstPropertyValue] += value
    }
    if (secondProperty) {
      if (!result[firstPropertyValue][secondPropertyValue]) {
        result[firstPropertyValue][secondPropertyValue] = value
      } else {
        result[firstPropertyValue][secondPropertyValue] += value
      }
    }
  })

  return result
}
const getChartConfig = (
  aggregatedData = {},
  xAxisValues = [],
  yAxisValues = [],
  minValue = 0,
  maxValue = 0,
  {
    height,
    width
  },
  xAxisLabelRotate) => {

  return {
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        const [xAxisValue, yAxisValue, value] = params.data.value

        return `${params.marker} ${xAxisValue}, ${yAxisValue} <span style="font-weight: 600; font-size: 15px">${value}</span>`
      },
      appendToBody: true,
      transitionDuration: 0,
      borderWidth: 0,
    },
    xAxis: {
      type: "category",
      data: xAxisValues,
    },
    yAxis: {
      type: "category",
      data: yAxisValues,
      position: "right"
    },
    visualMap: {
      min: minValue,
      max: maxValue,
      calculable: true,
      realtime: false,
      left: 16,
      bottom: 16,
      itemHeight: height - 54
    },
    series: [{
      type: "heatmap",
      data: xAxisValues.flatMap((xAxisValue) =>
        yAxisValues.map(yAxisValue => {
          const result = {
            value: [xAxisValue, yAxisValue, aggregatedData[xAxisValue][yAxisValue] || 0]
          }

          if (!aggregatedData[xAxisValue][yAxisValue]) {
            result.itemStyle = {
              color: "rgba(24, 144, 255, 0.1)",
              borderWidth: 0
            }
          }

          return result
        })),
      emphasis: {
        itemStyle: {
          border: "0.5px solid #EBEFF2",
          boxShadow: "0px 6px 9px rgba(39, 39, 39, 0.2)",
          borderRadius: "2px"
        }
      },
      progressive: 1000,
      animation: false
    }],
    grid: {
      top: 30,
      left: 100,
      containLabel: true,
      width: width - 110,
      height: height - 50
    }
  }
}
const DeclineAnalysisChartFive = React.memo(({
  requestData = {},
  valueField = "count",
  xField = "status",
  yField = "network",
  xAxisLabelRotate = 0
}) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));

  const { time_series: data } = requestData

  const myChart = useRef(null)
  const [chartSize, setChartSize] = useState({})

  useEffect(() => {
    const handleResize = () => {
      setChartSize({ height: myChart.current.clientHeight, width: myChart.current.clientWidth })
    }

    handleResize()

    window.addEventListener("resize", handleResize)

    // cleanup the events after the chart is disposed
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  let minValue = Infinity
  let maxValue = -Infinity

  const { xAxisValues, yAxisValues } = data.reduce((accumulator, item) => {
    accumulator.xAxisValues.add(item[xField])
    accumulator.yAxisValues.add(item[yField])

    minValue = Math.min(minValue, item[valueField])
    maxValue = Math.max(maxValue, item[valueField])

    return accumulator
  }, { xAxisValues: new Set(), yAxisValues: new Set() })

  let aggregatedData = getAggregatedData(data, valueField, xField, yField)

  return (
    <Item>
      <Typography gutterBottom variant="h5" component="div">
        Decline Code Distribution - Network Heatmap
      </Typography>
      <div ref={myChart} style={{ height: "320px" }}>
        {requestData?.time_series?.length > 0 ? <>
          <Chart

            options={getChartConfig(aggregatedData, [...xAxisValues], [...yAxisValues], minValue, maxValue, chartSize, xAxisLabelRotate)}
          />
        </> : 'No Data'}

      </div>

    </Item>
  );
})

export default DeclineAnalysisChartFive;
