import React, { useEffect, useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ReactECharts from 'echarts-for-react';
import { dayMonthFormattor, kFormatter } from '../../../utils';
import { Typography } from '@mui/material';


function splitArrayIntoCommonKeys(data){
    return data.time_series.reduce(function(newArr, obj) {                               
          var newObj = {
            original_total_amount: obj.original_total_amount,
            total_amount: obj.total_amount,
            date: obj.date,
            date_iso: obj.date_iso,
            day: obj.day,
            end_date_iso: obj.end_date_iso,
            month: obj.month,
            currency: obj.currency,
            start_date_iso: obj.start_date_iso,
            year: obj.year
          };   
          if(newArr['Cumulative settlement'])                                               
            newArr['Cumulative settlement'].push(newObj);                                   
          else                                                         
            newArr['Cumulative settlement'] = [ newObj ];                                    
          return newArr
        }, {});
}
const SelltlementsChartTwo = React.memo(({ data }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));

  const [chartOptions, setChartOptions] = useState( {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: []
    },
    grid: { top: 40, right: 8, bottom: 24, left: 36 },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: []
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function (value, index) {
          return kFormatter(value);
        }
      }
    },
    series: [
    ]
  })

  useEffect(() => {
    if (data?.time_series?.length) {
      const splitObj = splitArrayIntoCommonKeys(data)
      const legends = Object.keys(splitObj)
      let xValues = []
      splitObj[legends[0]].forEach(item => {
        xValues.push(dayMonthFormattor(item.date))
      })
      let seriesArr = []
      legends.forEach(legend=>{
        let singleSeries = {
          name: legend,
          type: 'line',
          data: []
        }
        splitObj[legend].forEach(item => {
          singleSeries.data.push(Math.floor(item.total_amount))
        })
        seriesArr.push(singleSeries)
      })
      if(xValues.length && legends) {
        setChartOptions({
          ...chartOptions,
          legend: {
            data: legends
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xValues,
          },
          series: seriesArr
        })
      } else {
        setChartOptions({
          ...chartOptions,
          title: {
            show: true,
            textStyle:{
              color:'#bcbcbc'
            },
            text: 'No Data',
            left: 'center',
            top: 'center'
          }
        })
      }
      
    }
  }, [data])
  return (
    <Item>
      <Typography gutterBottom variant="h5" component="div">
      Cumulative Settlement
    </Typography>
    {chartOptions && data?.time_series?.length > 0 ? <>
    <ReactECharts option={chartOptions} />
    </> : 'No Data'}</Item>
  );
})

export default SelltlementsChartTwo;
