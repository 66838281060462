import { Button, MenuItem, Select, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useContext, useState } from 'react';
import { loginCall } from '../../apiCalls';
import { AuthContext } from '../../context/AuthContext';
import styles from './login.module.css'
function Login() {
  localStorage.removeItem('token')
  localStorage.removeItem('env')
  const [token, setToken] = useState('');
  const [env, setEnv] = useState('DEV');
  const { isFetching, dispatch } = useContext(AuthContext);
  const handleSubmit = (event) => {
    if (token) {
      event.preventDefault();
      loginCall({ token: token, env: env }, dispatch);
    }
  };
  const handleOnChange = (event) => {
    setToken(event.target.value)
  };
  const handleChangeSelect = (event) => {
    setEnv(event.target.value)
  };
  return (
    <Container maxWidth="sm" className={styles.Body}>
      <Card className={styles.Card}>
        <Typography gutterBottom variant="h5" component="div">
          Auth Token
        </Typography>
        <TextField
          fullWidth
          style={{ margin: '16px 0' }}
          id="token"
          label="Input Token"
          value={token}
          onChange={handleOnChange}
          disabled={isFetching}
        />

        <Select
          fullWidth
          value={env}
          style={{ margin: '16px 0' }}
          onChange={handleChangeSelect}
          disabled={isFetching}
        >
          <MenuItem value={'DEV'}>DEV</MenuItem>
          <MenuItem value={'SANDBOX'}>SANDBOX</MenuItem>
          <MenuItem value={'QA'}>QA</MenuItem>
          <MenuItem value={'PROD'}>PROD</MenuItem>
        </Select>

        <br />
        <Button variant="contained" style={{ marginTop: '16px' }} disabled={isFetching} onClick={handleSubmit}>Login</Button>
      </Card>
    </Container>
  );
}

export default Login;
