import { DateTime } from "luxon"
export const dayMonthFormattor = (date) => {
    const dateTokens = date.split("-")
    const day = dateTokens[2]
    let month = ""
    switch (dateTokens[1]) {
        case "01":
            month = "Jan"
            break;
        case "02":
            month = "Feb"
            break;
        case "03":
            month = "Mar"
            break;
        case "04":
            month = "Apr"
            break;
        case "05":
            month = "May"
            break;
        case "06":
            month = "Jun"
            break;
        case "07":
            month = "Jul"
            break;
        case "08":
            month = "Aug"
            break;
        case "09":
            month = "Sep"
            break;
        case "10":
            month = "Oct"
            break;
        case "11":
            month = "Nov"
            break;
        default:
            month = "Dec"
    }
    return day + ' ' + month
}

export const kFormatter = (num) => {
	if (Math.abs(num) > 1e6) {
		return `${Math.sign(num) * (Math.abs(num) / 1e6).toFixed(2)}M`
	}
	return Math.abs(num) > 999
		? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(2)}K`
		: Math.abs(num) > 0 ? Number((Math.sign(num) * Math.abs(num)).toFixed(2)) : 0
}


export const percentageNumberFormatting = (number) => {
	const numberOfZeros = Math.abs(Math.floor(Math.log10(number) + 1))
	if (number > 0 && number < 1 && numberOfZeros > 1) {
		return number.toFixed(numberOfZeros + 2)
	}

	return number.toFixed(2)
}


const bigNumberFormatter = (num) => {
	num = Number(Number(num).toFixed(2))

	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}


export const formatterForMixedRateAndCount = (value) => ((typeof (value) === "string" && (value + "%")) || bigNumberFormatter(value))


const FREQUENCY_CONSTANTS = {
	HOURLY: "hourly",
	DAILY: "daily",
	WEEKLY: "weekly",
	MONTHLY: "monthly"
}

const getStartOfDateTimeDependingOnFrequency = (dateTime, frequency) => {
	if (frequency === FREQUENCY_CONSTANTS.HOURLY) {
		return dateTime.startOf("hour")
	}
	if (frequency === FREQUENCY_CONSTANTS.DAILY) {
		return dateTime.startOf("day")
	}
	if (frequency === FREQUENCY_CONSTANTS.WEEKLY) {
		return dateTime.startOf("week")
	}
	if (frequency === FREQUENCY_CONSTANTS.MONTHLY) {
		return dateTime.startOf("month")
	}
}

export const extract_date_time = (object, frequency, timeZone, periodEndDateISO) => {
	// TODO handle begging and ending of period
	const periodEndDate = DateTime.fromISO(periodEndDateISO)
	switch (frequency) {
		case "hourly": {
			let startDate = DateTime.utc(object.year, object.month, object.day, object.hour).startOf("hour")
			if (timeZone) {
				startDate = startDate.setZone(timeZone)
			}
			return [`${startDate.toFormat("HH:mm dd MMM")}`, startDate]
		}
		case "daily": {
			const startDate = DateTime.fromObject({
				year: object.year,
				month: object.month,
				day: object.day
			}).startOf("day")
			let formatString = "dd MMM"
			const currentDate = DateTime.now()
			if (startDate.year !== currentDate.year) {
				formatString += " yyyy"
			}
			return [startDate.toFormat(formatString), startDate]
		}
		case "weekly": {
			const startOfWeek = getDateOfISOWeek(object.week, object.year)
			const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6)

			const startDate = DateTime.fromJSDate(startOfWeek)
			let endDate = DateTime.fromJSDate(endOfWeek)

			if (endDate > periodEndDate) {
				endDate = periodEndDate
			}

			let formatString = "dd MMM"
			const currentDate = DateTime.now()
			if (startDate.year !== currentDate.year) {
				formatString += " yyyy"
			}
			return [`${startDate.toFormat(formatString)} - ${endDate.toFormat(formatString)}`, startDate]
		}
		case "monthly": {
			const startDate = DateTime.fromObject({
				year: object.year,
				month: object.month
			}).startOf("month")

			let formatString = ""
			const currentDate = DateTime.now()
			if (startDate.year !== currentDate.year) {
				formatString = "MMM yyyy"
			} else {
				formatString = "MMM"
			}
			return [`${startDate.toFormat(formatString)}`, startDate]
		}
		default: {
			return [object.data_iso, DateTime.fromISO(object.data_iso)]
		}
	}
}

function getDateOfISOWeek(w, y) {
	const simple = new Date(y, 0, 1 + (w - 1) * 7)
	const dayOfWeek = simple.getDay()
	const ISOweekStart = simple
	if (dayOfWeek <= 4) {
		ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1)
	} else {
		ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay())
	}
	return ISOweekStart
}

export const addEmptyItems = (aggregatedData, dataOptions, additionalAggregationPropertyValues, timeZone) => {
	const result = {}
	let currentDate = getStartOfDateTimeDependingOnFrequency(DateTime.fromISO(dataOptions.start_end.start, { zone: timeZone }), dataOptions.quanta)
	const endDate = getStartOfDateTimeDependingOnFrequency(DateTime.fromISO(dataOptions.start_end.end, { zone: timeZone }), dataOptions.quanta)

	while (currentDate <= endDate) {
		const newItem = {
			day: currentDate.day,
			month: currentDate.month,
			year: currentDate.year,
			hour: currentDate.hour,
			week: currentDate.weekNumber
		};
		[newItem.date, newItem.dateTimeObject] = extract_date_time(newItem, dataOptions.quanta, null, dataOptions.start_end.end)
		const newItemDate = newItem.date

		if (additionalAggregationPropertyValues && additionalAggregationPropertyValues.length > 0) {
			result[newItemDate] = {}
		}

		if (!aggregatedData[newItemDate]) {
			if (additionalAggregationPropertyValues && additionalAggregationPropertyValues.length > 0) {
				additionalAggregationPropertyValues.forEach((value) => {
					result[newItemDate][value] = 0
				})
			} else {
				result[newItemDate] = 0
			}
		} else if (additionalAggregationPropertyValues && additionalAggregationPropertyValues.length > 0) {
			additionalAggregationPropertyValues.forEach((value) => {
				if (!aggregatedData[newItemDate][value]) {
					result[newItemDate][value] = 0
				} else {
					result[newItemDate][value] = aggregatedData[newItemDate][value]
				}
			})
		} else {
			result[newItemDate] = aggregatedData[newItemDate]
		}

		if (dataOptions.quanta === FREQUENCY_CONSTANTS.HOURLY) {
			currentDate = currentDate.plus({ hours: 1 })
		}
		if (dataOptions.quanta === FREQUENCY_CONSTANTS.DAILY) {
			currentDate = currentDate.plus({ days: 1 })
		}
		if (dataOptions.quanta === FREQUENCY_CONSTANTS.WEEKLY) {
			currentDate = currentDate.plus({ weeks: 1 })
		}
		if (dataOptions.quanta === FREQUENCY_CONSTANTS.MONTHLY) {
			currentDate = currentDate.plus({ months: 1 })
		}
	}

	return result
}
