import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Chart } from '../../shared/chart/chart'
import { Typography } from '@mui/material';
import { addEmptyItems, extract_date_time } from '../../../utils';
import React from 'react';
function getAggregatedData(data, valueProperty, firstProperty, secondProperty, thirdProperty) {
    const result = {}
    data.forEach((item) => {
        const firstPropertyValue = item[firstProperty]
        const secondPropertyValue = item[secondProperty]
        const thirdPropertyValue = item[thirdProperty]
        const value = item[valueProperty]

        if (!result[firstPropertyValue]) {
            result[firstPropertyValue] = !secondProperty ? value : {}
        } else if (!secondProperty) {
            result[firstPropertyValue] += value
        }

        if (secondProperty) {
            if (!result[firstPropertyValue][secondPropertyValue]) {
                result[firstPropertyValue][secondPropertyValue] = !thirdProperty ? value : {}
            } else {
                if (!thirdProperty) {
                    result[firstPropertyValue][secondPropertyValue] += value
                }
            }
        }

        if (thirdProperty) {
            if (!result[firstPropertyValue][secondPropertyValue][thirdPropertyValue]) {
                result[firstPropertyValue][secondPropertyValue][thirdPropertyValue] = value
            } else {
                result[firstPropertyValue][secondPropertyValue][thirdPropertyValue] += value
            }
        }
    })

    return result
}

const getChartConfig = (
    aggregatedDataBarChart = {},
    aggregatedDataLineChart = {},
    xAxisValues = [],
    chartsNames = [],
    colors,
    {
        yAxisBarLabelFormatter,
        yAxisLineLabelFormatter,
        lineValueFormatter = (value) => value,
        tooltipFormatter
    } = {},
    {
        lineChartName,
        barChartName
    } = {}
) => {
    const showDots = Object.values(aggregatedDataLineChart).length < 2

    return {
        tooltip: {
            trigger: "item",
            axisPointer: {
                type: "shadow"
            },
            appendToBody: true,
            transitionDuration: 0,
            formatter: tooltipFormatter
        },
        legend: {
            data: [barChartName, lineChartName],
            width: "85%",
            type: "scroll",
            orient: "horizontal",
            icon: "roundRect"
        },
        grid: {
            containLabel: true,
            left: 24,
            top: 40,
            bottom: 30
        },
        xAxis: [
            {
                type: "category",
                data: xAxisValues,
                axisPointer: {
                    show: true,
                    type: "line",
                    label: {
                        show: false
                    }
                }
            }
        ],
        yAxis: [
            {
                type: "value",
                axisLabel: {
                    formatter: yAxisBarLabelFormatter
                }
            },
            {
                type: "value",
                axisLabel: {
                    formatter: yAxisLineLabelFormatter
                }
            }
        ],
        series: [
            {
                name: barChartName,
                type: "bar",
                data: Object.values(aggregatedDataBarChart),
            },
            {
                name: lineChartName,
                type: "line",
                yAxisIndex: 1,
                data: Object.values(aggregatedDataLineChart).map(item => lineValueFormatter(item)),
                showSymbol: showDots,
                showAllSymbol: showDots,
            }
        ]
    }
}
const ChargebacksChartFive = React.memo(({
    requestData = {},
    params = {},
    timeZone = "UTC",
    xField = "date",
    yBarField = "count",
    yLineField = "rate",
    isAddingEmptyItemsRequired = false,
    colors = {},
    chartOptions = {},
    chartsNames = {}
}) => {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        height: '370px',
        color: theme.palette.text.secondary,
    }));

    const { time_series: data } = requestData
    const { frequency, start_date, end_date } = params

    
    data.forEach((x) => {
        if (x.date) {
            [x.date, x.dateTimeObject] = extract_date_time(x, frequency.name, timeZone, end_date)
        }
    })
    let aggregatedDataBarChart = getAggregatedData(data, yBarField, xField)
    let aggregatedDataLineChart = getAggregatedData(data, yLineField, xField)

    if (isAddingEmptyItemsRequired) {
        aggregatedDataBarChart = addEmptyItems(aggregatedDataBarChart, { start_end: { start_date, end_date }, quanta: frequency.name }, [], timeZone)
        aggregatedDataLineChart = addEmptyItems(aggregatedDataLineChart, { start_end: { start_date, end_date }, quanta: frequency.name }, [], timeZone)
    }

    const xAxisValues = Object.keys(aggregatedDataBarChart)
    return (
        <Item>
            <Typography gutterBottom variant="h5" component="div">
                Chargeback Volume and Rate by Issuer
            </Typography>
            {chartOptions && requestData?.time_series?.length > 0 ? <>
                <Chart options={getChartConfig(aggregatedDataBarChart, aggregatedDataLineChart, xAxisValues, chartsNames, colors, chartOptions, chartsNames)} />
            </> : 'No Data'}

        </Item>
    );
})

export default ChargebacksChartFive;