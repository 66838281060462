import React, { useState, useCallback, useMemo } from "react"
import { Chart } from '../../shared/chart/chart'
import BarChartIcon from "./img/bar-chart.svg"
import BarChartActiveIcon from "./img/bar-chart-active.svg"
import WorldMapIcon from "./img/world-map.svg"
import WorldMapActiveIcon from "./img/world-map-active.svg"
import Paper from '@mui/material/Paper'
import { experimentalStyled as styled } from '@mui/material/styles';
import { Typography } from "@mui/material"

export const MapMorphChart = ({
	requestData = {},
	nameField = "pm_card_issuer_country",
	nameFormatter,
	valueField = "rate",
	colorField = valueField,
	colorFieldFormatter,
	colors = {}
}) => {
	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(2),
		textAlign: 'center',
		height: '370px',
		color: theme.palette.text.secondary,
	}));
	const { time_series: data } = requestData


	const [isMapMode, setIsMapMode] = useState(true)

	const toggleMode = useCallback(() => {
		setIsMapMode(!isMapMode)
	}, [isMapMode])

	const chartData = useMemo(() => {
		return data.sort((itemA, itemB) => itemA[valueField] - itemB[valueField]).map((item) => {
			const name = item[nameField]
			const value = item[valueField]
			const colorValue = item[colorField]

			return {
				name,
				value: [value, name, colorValue]
			}
		}, [[], []])
	}, [data, nameField, colorField, valueField])

	const tooltipFormatter = useCallback((props) => {
		const { data } = props
		if (!data) {
			const countryCode = props.name
			return `<span class="fs13 fw400 lh16">${nameFormatter ? nameFormatter(countryCode) : countryCode}`
		}

		const [value, name, colorValue] = data.value

		const color = Number(colorValue.toFixed(2))

		return `<span class="fs13 fw400 lh16">${nameFormatter ? nameFormatter(name) : name} - </span><span class="fs14 fw700 lh22">${value} (${color}%)</span>`
	}, [nameFormatter])

	const max = 2
	const min = 0

	const options = useMemo(() => {
		if (isMapMode) {
			return {
				grid: {
					bottom: '33%',
				},
				series: {
					id: "countries",
					type: "map",
					name: "Countries",
					nameProperty: "iso_a2",
					map: "worldMap",
					emphasis: {
						label: {
							show: false
						},
						itemStyle: {
							areaColor: null,
							shadowColor: "rgba(0, 0, 0, 0.25)",
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowOffsetY: 0
						}
					},
					animationDurationUpdate: 1000,
					universalTransition: true,
					data: chartData
				},
				tooltip: {
					trigger: "item",
					formatter: tooltipFormatter
				},
				visualMap: {
					bottom: "18%",
					left: "right",
					min,
					max,
					inRange: {
						color: colors
					},
					dimension: 2,
					precision: 2,
					text: ["High", "Low"],
					formatter: (value) => colorFieldFormatter ? colorFieldFormatter(Number(value.toFixed(2))) : Number(value.toFixed(2)),
					calculable: true
				},
				toolbox: {
					itemSize: 24,
					itemGap: 0,
					feature: {
						myMapButton: {
							icon: `image://${WorldMapActiveIcon}`,
							onclick: toggleMode
						},
						myBarButton: {
							icon: `image://${BarChartIcon}`,
							onclick: toggleMode
						}
					}
				}
			}
		}

		return {
			series: {
				id: "countries",
				name: "Countries",
				type: "bar",
				animationDurationUpdate: 1000,
				universalTransition: true,
				data: chartData,
				emphasis: {
					label: {
						show: false
					},
					itemStyle: {
						areaColor: null,
						shadowColor: "rgba(0, 0, 0, 0.25)",
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowOffsetY: 0
					}
				}
			},
			tooltip: {
				trigger: "item",
				formatter: tooltipFormatter
			},
			visualMap: {
				bottom: "18%",
				left: "right",
				min,
				max,
				inRange: {
					color: colors
				},
				dimension: 2,
				precision: 2,
				text: ["High", "Low"],
				formatter: (value) => colorFieldFormatter ? colorFieldFormatter(Number(value.toFixed(2))) : Number(value.toFixed(2)),
				calculable: true
			},
			xAxis: {
				type: "value"
			},
			yAxis: {
				type: "category"
			},
			toolbox: {
				itemSize: 24,
				itemGap: 0,
				feature: {
					myMapButton: {
						icon: `image://${WorldMapIcon}`,
						onclick: toggleMode
					},
					myBarButton: {
						icon: `image://${BarChartActiveIcon}`,
						onclick: toggleMode
					}
				}
			},
			grid: {
				bottom: '33%',
			},
		}
	}, [isMapMode, chartData, nameFormatter, colorFieldFormatter, colors, tooltipFormatter, toggleMode])

	return (
		<Item>
			<Typography gutterBottom variant="h5" component="div">
				Chargeback Rate and Volume by Issuing Country
			</Typography>
			{options && requestData?.time_series?.length > 0 ? <>
				<Chart options={options}  />
			</> : 'No Data'}
		</Item>
	)
}